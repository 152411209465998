<template>
    <div class="flex" style="height: 100%;">
        <div class="flex-item">
            <div class="tableModule">
                <div class="title">
                    <div class="title-left">
                        <i class="el-icon-s-operation"></i>
                        <span>字典类型</span>
                    </div>
                    <div class="title-right">
                        <el-button type="primary" size="medium" @click="insert(1)" style="margin-right: 8px;">
                            新增字典类型
                        </el-button>
                    </div>
                </div>
                <div class="tableContainer">
                    <div class="table" ref="tableContainer">
                        <div>
                            <el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
                                :header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ 'padding': 0 }"
                                style="margin-bottom: 20px;">
                                <el-table-column align="center" label="序号">
                                    <template slot-scope="scope">
                                        <div>{{ scope.$index + 1 }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="code" align="center" label="编码" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="name" align="center" label="名称" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column fixed="right" align="center" label="操作" width="160">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="setData(scope.row)">
                                            添加字典
                                        </el-button>
                                        <el-button type="text" @click="edit(scope.row, 1)">
                                            编辑
                                        </el-button>
                                        <el-button type="text" @click="remove(scope.row, 1)">
                                            删除
                                        </el-button>

                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination style="float: right;" @current-change="handleCurrentChange"
                            :current-page.sync="searchParm.page" :page-size="searchParm.pageSize"
                            layout="total, prev, pager, next,jumper" :total="tableData.total">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
        </div>
        <div class="flex-item">
            <div class="tableModule">
                <div class="title">
                    <div class="title-left">
                        <i class="el-icon-s-operation"></i>
                        <span>{{ name?name+'-字典数据':'字典数据' }}</span>
                    </div>
                    <div class="title-right">
                        <el-button v-if="searchParm2.typeCode" type="primary" size="medium" @click="insert(2)"
                            style="margin-right: 8px;">
                            新增数据
                        </el-button>
                    </div>
                </div>
                <div class="tableContainer">
                    <div class="table" ref="tableContainer2">
                        <div>
                            <el-table ref="usertable2" :data="tableData2.records" border :height="tableHeight"
                                :header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ 'padding': 0 }"
                                style="margin-bottom: 20px;">
                                <el-table-column align="center" label="序号">
                                    <template slot-scope="scope">
                                        <div>{{ scope.$index + 1 }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="value" align="center" label="值" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column prop="name" align="center" label="名称" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column fixed="right" align="center" label="操作" width="160">
                                    <template slot-scope="scope">
                                        <el-button type="text" @click="edit(scope.row, 2)">
                                            编辑
                                        </el-button>
                                        <el-button type="text" @click="remove(scope.row, 2)">
                                            删除
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination style="float: right;" @current-change="handleCurrentChange2"
                            :current-page.sync="searchParm2.page" :page-size="searchParm2.pageSize"
                            layout="total, prev, pager, next,jumper" :total="tableData.total">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <edit2 :isShow.sync="showEditDialog2" :dialogObj="editObj2"></edit2>
        </div>
    </div>
</template>
<script>
import smCard from "@c/smCard.vue"
import edit from "./edit.vue"
import edit2 from "./edit2.vue"

export default {
    components: {
        smCard,
        edit,
        edit2,

    },
    data() {
        return {
            tableHeight: 0,
            tableData: {
                records: [],
            },
            searchParm: {
                pageSize: 20,
                page: 1
            },
            tableData2: {
                records: [],
            },
            searchParm2: {
                pageSize: 20,
                page: 1
            },
            showEditDialog: false,
            showEditDialog2: false,
            editObj: {},
            editObj2: {},
            name: "",
        }
    },
    methods: {
        //自适应表格高度
        calHeight() {
            this.$nextTick(() => {
                const rect = this.$refs.tableContainer.getBoundingClientRect()
                this.tableHeight = rect.height
            })
        },
        //清空搜索
        clearSearch() {
            this.searchParm = {
                pageSize: 20,
                page: 1
            }
        },
        //清空搜索
        clearSearch2() {
            this.searchParm2 = {
                pageSize: 20,
                page: 1
            }
        },
        //展示字典类型数据
        list() {
            this.$get("/backend-api/backend/api/v1/sys-dict-type", this.searchParm).then((res) => {
                if (res.code == 1000) {
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //展示字典数据
        datalist() {
            this.$get("/backend-api/backend/api/v1/sys-dict-item", this.searchParm2).then((res) => {
                if (res.code == 1000) {
                    this.tableData2 = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //打开新增弹窗
        insert(e) {
            if (e == 1) {
                this.editObj = {};
                this.editObj["type"] = 1;
                this.showEditDialog = true;
            } else {
                this.editObj2 = {};
                this.editObj2["type"] = 1;
                this.editObj2["typeCode"] = this.searchParm2.typeCode;
                this.showEditDialog2 = true;
            }

        },
        //打开编辑弹窗
        edit(row, e) {
            this.$confirm("敏感操作！修改后将影响系统，请谨慎操作！", "重要操作！", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (e == 1) {
                    this.editObj = {};
                    this.editObj = { ...row };
                    this.editObj["type"] = 2;
                    this.showEditDialog = true;
                } else {
                    this.editObj2 = {};
                    this.editObj2 = { ...row };
                    this.editObj2["type"] = 2;
                    this.showEditDialog2 = true;
                }
            }).catch(() => { });

        },
        //分页选择页面回调
        handleCurrentChange(val) {
            this.searchParm.page = val;
            this.list();
        },
        //分页选择页面回调
        handleCurrentChange2(val) {
            this.searchParm2.page = val;
            this.datalist();
        },
        //删除
        remove(row, e) {
            this.$confirm("是否确认删除？删除后无法复原！", "重要操作！", {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = "";
                if (e == 1) {
                    url = "/backend-api/backend/api/v1/sys-dict-type/";
                } else {
                    url = "/backend-api/backend/api/v1/sys-dict-item/";
                }


                this.$remove(url + row.id).then((res) => {
                    if (res.code == 1000) {
                        this.$message.success('删除成功');
                        //刷新表格
                        if (e == 1) {
                            this.list();
                        } else {
                            this.datalist();
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }).catch(() => { });
        },
        setData(row) {
            this.searchParm2.typeCode = row.code;
            this.name = row.name;

            this.datalist();
        }
    },
    mounted() {
        this.list();
        this.calHeight();
        window.addEventListener('resize', this.calHeight)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calHeight)
    },
};
</script>
<style lang='scss' scoped>
@import "@style/userCenter.scss";

.tableModule .tableContainer {
    ::v-deep .table .cell {
        text-overflow: clip;
        white-space: normal;
        overflow: auto;
        word-break: break-word;
    }
}

.flex-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    margin: 0 10px;
}
</style>
