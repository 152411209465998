<template>
    <el-dialog class="dialog" :title="title" :visible.sync="visiable">
        <div v-loading="loading" class="dlalog-body">
            <el-form ref="form" :model="formData" label-width="140px" :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="编码：" prop="code">
                            <el-input v-model="formData.code"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称：" prop="name">
                            <el-input v-model="formData.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close()">关闭</el-button>
                <el-button type="primary" @click="submit()">确定</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import {
    dialogMixin
} from "@m/dialogMixin"
export default {
    mixins: [dialogMixin],
    data() {
        return {
            title: "",
            loading: false,
            formData: {},
            rules: {
                code: [{
                    required: true,
                    message: "此项必填",
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: '此项必填',
                    trigger: 'blur'
                }],
            }
        };
    },
    methods: {
        //打开弹窗
        open() {
            this.$nextTick(() => {
                this.$refs.form.clearValidate();
            })
            if (this.dialogObj && this.dialogObj.type == 2) {
                this.title = "编辑字典类型"
                this.formData = this.dialogObj;
            } else {
                this.title = "新增字典类型"
                this.formData = {};

            }
        },

        //提交表单
        submit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true;

                    var apiUrl = '/backend-api/backend/api/v1/sys-dict-type';
                    var requestMethod = this.dialogObj.type == 1 ? this.$postJson : this.$putJson;
                    
                    requestMethod(apiUrl, this.formData).then(res => {
                        if (res.code == 1000) {
                            this.loading = false;
                            this.$message.success('操作成功');
                            this.$parent.list();
                            this.close();
                        } else {
                            this.loading = false;
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        //关闭弹窗
        close() {
            this.visiable = false
        }
    }
};
</script>
<style lang='scss' scoped>
.dialog {
    ::v-deep.cascader {
        display: block;
    }

    ::v-deep.el-image {
        width: 80px;
        height: 80px;
        margin-right: 20px;
    }

    .images {
        margin: 10px 25px;
    }
}
</style>
